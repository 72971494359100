import { i18n } from '@/i18n'
import { parserI18N } from '@/utils/i18nFunc.js'

const state = {
  locale: undefined,
  langs: [],
  messages: {}
}

const getters = {
  key (state) {
    for (let i = 0; i < state.langs.length; i++) {
      if (state.langs[i].iso === state.locale) {
        return state.langs[i].code
      }
    }
    return state.locale
  },
  keys (state) {
    const ans = []
    state.langs.forEach(lang => {
      ans.push(lang.iso)
    })
    return ans
  },
  lang: state => state.locale,
  langs: state => state.langs,
  wording: state => state.messages
}

const mutations = {
  SET_LANGS (state, langs) {
    langs.forEach((lang, index) => {
      langs[index]['key'] = langs[index].iso.split('-')[0] // eslint-disable-line
    })
    state.langs = langs
  },
  SET_LANG (state, lang) {
    state.locale = lang
    i18n.locale = lang
  },
  SET_KEY (state, key) {
    for (let i = 0; i < state.langs.length; i++) {
      if (state.langs[i].code === key) {
        i18n.locale = state.langs[i].iso
        state.locale = state.langs[i].iso
        break
      }
    }
  },
  SET_MESSAGES (state, messages) {
    const parsedWording = parserI18N(messages, state.langs)
    for (let i = 0; i < state.langs.length; i++) {
      const iso = state.langs[i].iso
      if (parsedWording[iso]) i18n.setLocaleMessage(iso, parsedWording[iso])
    }
    state.messages = messages
  }
}

const actions = {
  setLangs ({ commit }, langs) {
    commit('SET_LANGS', langs)
  },
  setLang ({ commit }, lang) {
    commit('SET_LANG', lang)
  },
  setKey ({ commit }, key) {
    commit('SET_KEY', key)
  },
  setMessages ({ commit }, mess) {
    commit('SET_MESSAGES', mess)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
