<template>
  <div class="Panel" v-if="this.$store.getters['session/settingsOpen']">
    <div class="CloseButton" @click="ClosePanel">
      <img :src="require('../../assets/icons/close.svg')" alt="close" />
    </div>
    <div class="Panel__Content">
      <div class="Panel__ContentScroll">
        <div class="Panel__Top">
          <div class="Panel__Fields">
            <div class="Panel__Fields-section">
              <p>{{ $t('yourFirstName') }}</p>
              <div class="field">
                <LoginField
                  :input-type="'text'"
                  :placeholder="`${$t('firstName')}`"
                  :autocompletion="firstName"
                  @textChanged="UpdateFirstName($event)"
                />
              </div>
            </div>
            <div class="Panel__Fields-section">
              <p>{{ $t('yourLastName') }}</p>
              <div class="field">
                <LoginField
                  :input-type="'text'"
                  :placeholder="`${$t('lastName')}`"
                  :autocompletion="lastName"
                  @textChanged="UpdateLastName($event)"
                />
              </div>
            </div>
          </div>
          <div class="Panel__Cgus">
            <div
              class="Panel__Checkbox"
              v-for="(key, index) in cguKeys"
              :key="index"
            >
              <div class="Panel__Checkbox-content">
                <p
                  v-html="formatText($t(`cgu.${key}.agree`))"
                  @click="seeMoreIndex = index"
                />
                <p class="Panel__Checkbox-sub">
                  {{ $t('lastUpdate') }}: {{ getDate(index) }} at
                  {{ getTime(index) }}
                </p>
              </div>
              <switch-button
                :active="selectedCgus.map(el => el.index).includes(index)"
                @check="checkBox(index)"
              />
            </div>
          </div>
          <div class="Panel__Warnings">
            <p>{{ $t('cguWarning') }}</p>
          </div>
          <div
            v-if="$store.getters['langs/langs'].length > 1"
            class="Panel__Langs"
          >
            <p>{{ $t('chooseLang') }} :</p>
            <LangDropdown />
          </div>
        </div>
        <div class="Panel__Buttons">
          <CTA
            class="Panel__Button"
            :label="$t('update')"
            @clickButton="validate"
            :radius="false"
          />
          <CTA
            class="Panel__Button"
            :label="$t('logOut')"
            @clickButton="logout"
            :color="$store.getters['theme/theme'].secondary"
            outline
            :radius="false"
          />
          <p @click="deleteProfile">{{ $t('deleteProfile') }}</p>
        </div>
      </div>
    </div>
    <validation-modale
      v-if="askForRefuseCgus"
      :text="$t('areYouSureRefuse')"
      @closeValidModale="closeValidModale"
    />
    <validation-modale
      v-if="askForDelete"
      :text="$t('areYouSureDelete')"
      @closeValidModale="closeValidModale"
    />
    <modale-cgu
      v-if="seeMoreIndex || seeMoreIndex === 0"
      :title="$t(`cgu.${cguKeys[seeMoreIndex]}.title`)"
      :description="$t(`cgu.${cguKeys[seeMoreIndex]}.content`)"
      @closeModaleCgu="closeModale"
    />
  </div>
</template>

<script>
import CTA from '@/components/button/CTA'
import LoginField from '../../views/login/components/LoginField'
import ModaleCgu from '@/components/modale/ModaleCgu'
import ValidationModale from '@/components/modale/ValidationModale'
import SwitchButton from '@/components/button/SwitchButton'
import LangDropdown from '@/components/language/LangDropdown'
import { formatDate, formatTime } from '@/utils/date'

export default {
  components: {
    LoginField,
    ModaleCgu,
    SwitchButton,
    CTA,
    ValidationModale,
    LangDropdown
  },
  data () {
    return {
      firstName: null,
      lastName: null,
      seeMoreIndex: null,
      selectedCgus: [],
      lastUpdateTime: null,
      askForRefuseCgus: false,
      askForDelete: false
    }
  },
  created () {
    if (this.cacheInfos) {
      this.firstName = this.cacheInfos.firstName
      this.lastName = this.cacheInfos.lastName
      this.selectedCgus = this.cacheInfos.access
      this.lastUpdateTime = this.cacheInfos.updatedAt
    }
  },
  computed: {
    cacheInfos () {
      return JSON.parse(localStorage.getItem('user-infos'))
    },
    wording () {
      return this.$store.getters['langs/wording']
    },
    placeholder () {
      return 'Email'
    },
    cguKeys () {
      return Object.keys(this.wording.cgu)
    },
    cguTitles () {
      return this.cguKeys.map(el => this.$t(`cgu.${el}.title`))
    },
    cguContents () {
      return this.cguKeys.map(el => this.$t(`cgu.${el}.content`))
    },
    cguValid () {
      const cguKeys = this.selectedCgus.map(el => el.key)
      const hasTerms = cguKeys.filter(el => el.includes('terms')).length > 0
      const hasPrivacy = cguKeys.filter(el => el.includes('privacy')).length > 0
      return hasTerms && hasPrivacy
    }
  },
  methods: {
    getDate (i) {
      const date = this.selectedCgus[i]
        ? this.selectedCgus[i].timestamp
        : this.cacheInfos.updatedAt
      return formatDate(date)
    },
    getTime (i) {
      const date = this.selectedCgus[i]
        ? this.selectedCgus[i].timestamp
        : this.cacheInfos.updatedAt
      return formatTime(date)
    },
    ClosePanel () {
      this.$store.dispatch('session/SetSettingsOpen', false)
    },
    UpdateFirstName (e) {
      this.firstName = e
    },
    UpdateLastName (e) {
      this.lastName = e
    },
    checkBox (nb) {
      const keys = this.selectedCgus.map(el => el.index)
      if (!keys.includes(nb)) {
        const isRequired =
          this.cguTitles[nb].includes('terms') ||
          this.cguTitles[nb].includes('privacy')
        if (isRequired) {
          this.selectedCgus.push({
            key: this.cguKeys[nb],
            index: nb,
            required: true,
            timestamp: new Date().getTime()
          })
        } else {
          this.selectedCgus.push({
            key: this.cguKeys[nb],
            index: nb,
            required: false,
            timestamp: new Date().getTime()
          })
        }
      } else {
        const index = keys.indexOf(nb)
        this.selectedCgus.splice(index, 1)
      }
    },
    seeMore (index) {
      this.seeMoreIndex = index
    },
    closeModale (acceptsCgu) {
      const keys = this.selectedCgus.map(el => el.index)
      if (
        (acceptsCgu && !keys.includes(this.seeMoreIndex)) ||
        (!acceptsCgu && keys.includes(this.seeMoreIndex))
      ) {
        this.checkBox(this.seeMoreIndex)
      }
      this.seeMoreIndex = null
    },
    formatText (txt) {
      return txt.split('<span>').join('<span class="underline">')
    },
    validate (validate = false) {
      if (
        this.cguValid ||
        (!this.cguValid && typeof validate === 'boolean' && validate)
      ) {
        // 2. after validate modale, can save cgus
        const obj = {
          firstName: this.firstName,
          lastName: this.lastName,
          access: this.selectedCgus,
          updatedAt: new Date().getTime()
        }
        localStorage.setItem('user-infos', JSON.stringify(obj))
        this.ClosePanel()
      } else if (!validate && !this.cguValid) {
        // 1. ask for validation modale before if cgus are not complete
        this.askForRefuseCgus = true
      }
    },
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('events')
      this.ClosePanel()
      this.$router.push({ name: 'login' })
    },
    deleteProfile (validate = false) {
      if (typeof validate === 'boolean' && validate) {
        // 2. after validate modale, can delete
        localStorage.clear()
        this.ClosePanel()
        this.$router.push({ name: 'login' })
      } else {
        // 1. ask for validation modale before
        this.askForDelete = true
      }
    },
    closeValidModale (bool) {
      if (this.askForDelete) {
        this.askForDelete = false
        if (bool) this.deleteProfile(true)
      }
      if (this.askForRefuseCgus) {
        this.askForRefuseCgus = false
        if (bool) this.validate(true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/main.scss';

.Panel {
  position: absolute;
  width: 25%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: flex;
  @media (orientation: landscape) {
    left: 60px;
  }
  @media (orientation: portrait) {
    width: 45%;
    min-width: 350px;
    bottom: 60px;
    height: auto;
  }
  @media screen and (max-width: 1600px) and (orientation: landscape) {
    width: 30%;
  }
  @media screen and (max-width: 1440px) and (orientation: landscape) {
    width: 34%;
  }
  @media screen and (max-width: 752px) and (orientation: landscape) {
    width: 50%;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
}

.CloseButton {
  width: 45px;
  height: 45px;
  border: solid 2px black;
  box-sizing: border-box;
  border-radius: 100%;
  position: absolute;
  top: 16px;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
  &:active {
    opacity: 0.2;
  }
  @media screen and (max-width: 1400px) and (orientation: landscape) {
    top: 6px;
    left: 20px;
    width: 35px;
    height: 35px;
  }
  @media screen and (max-width: 768px) and (orientation: portrait) {
    top: 6px;
    left: 20px;
    width: 35px;
    height: 35px;
  }
  @media screen and (max-width: 375px) {
    top: 6px;
    left: 6px;
    width: 30px;
    height: 30px;
  }
}
.Panel__Content {
  height: calc(100% - 110px);
  width: 100%;
  box-sizing: border-box;
  margin: 80px 30px 30px 30px;
  @media screen and (max-width: 1400px) and (orientation: landscape) {
    margin: 60px 20px 20px 20px;
    height: calc(100% - 80px);
  }
  @media screen and (max-width: 768px) and (orientation: portrait) {
    box-sizing: border-box;
    margin: 60px 30px 30px 30px;
    height: calc(100% - 90px);
  }
  @media screen and (max-width: 425px) {
    margin: 50px 13px 13px 13px;
    height: calc(100% - 63px);
  }
  @media screen and (max-width: 375px) {
    margin: 50px 13px 13px 13px;
    height: calc(100% - 63px);
  }
  @media screen and (max-height: 742px) and (orientation: landscape) {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
}

.Panel__ContentScroll {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-height: 742px) and (orientation: landscape) {
    width: 100%;
    height: auto;
    overflow-y: scroll;
  }
}

.Panel__Top {
  height: 55%;
  @media screen and (max-height: 742px) and (orientation: landscape) {
    height: auto;
    margin-bottom: 10%;
  }
}

.Panel__Fields {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 35%;
  margin-bottom: 10%;
  p {
    font-family: 'Gotham Bold';
    margin-bottom: 5px;
  }
  @media screen and (max-height: 742px) and (orientation: landscape) {
    height: 171px;
    margin-bottom: 20px;
  }
}
.Panel__Langs {
  margin-top: 3%;
  p {
    font-family: 'Gotham Bold';
    margin-bottom: 5px;
  }
}
.Panel__Fields-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 45%;
  width: 100%;
}
.field {
  height: 60%;
}
.Panel__Cgus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55%;
  width: 100%;
  @media screen and (max-height: 742px) and (orientation: landscape) {
    height: 190px;
  }
}
.Panel__Checkbox {
  height: 23%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-height: 742px) and (orientation: landscape) {
    margin-bottom: 3%;
  }
}
.Panel__Checkbox-content {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.Panel__Checkbox-sub {
  color: #c8c8c8;
  font-family: 'Gotham LightItalic';
  @include fluid-type($min_width, $max_width, $min_font * 0.9, $max_font * 0.7);
}

p,
p /deep/ span {
  @include fluid-type($min_width, $max_width, $min_font * 1.1, $max_font * 0.8);
  line-height: 1.1rem;
  font-family: 'Gotham Book';
}

p /deep/ span {
  font-family: 'Gotham Medium';
  text-decoration: underline;
}

.Panel__Buttons {
  p {
    text-align: center;
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 0.8,
      $max_font * 0.5
    );
    font-family: 'Gotham Bold';
    text-transform: uppercase;
    text-decoration: underline;
  }
  @media screen and (max-height: 742px) and (orientation: landscape) {
    height: 113px;
  }
}

.Panel__Button {
  height: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  @media screen and (max-width: 1400px) and (orientation: landscape) {
    height: 2rem;
  }
  @media screen and (max-width: 375px) {
    height: 1.5rem;
    margin-bottom: 3%;
  }
}

.Panel__Warnings {
  margin-top: 10%;
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 0.5rem;
  width: 100%;
  p {
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 0.9,
      $max_font * 0.6
    );
    font-family: 'Gotham LightItalic';
    line-height: 1rem;
  }
  @media screen and (max-height: 742px) and (orientation: landscape) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

div,
p {
  touch-action: auto !important;
  -webkit-overflow-scrolling: touch;
}
</style>
