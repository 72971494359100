<template>
  <div class="Button_Container">
    <button
      :class="['Button_Btn', { inactive: !active }]"
      :style="{
        'border-radius': radius ? '30px' : 'none',
        'background-color': outline ? reverseColor : color,
        color: outline ? color : reverseColor,
        border: `1px solid ${color}`
      }"
      @click="active ? $emit('clickButton') : null"
    >
      {{ label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'cta',
  props: {
    label: { type: String, default: 'OK' },
    active: { type: Boolean, default: true },
    outline: { type: Boolean, default: false },
    color: { type: String, default: 'black' },
    reverseColor: { type: String, default: 'white' },
    radius: { type: Boolean, default: true }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/main.scss';

.Button_Container {
  width: 100%;
  height: 100%;
  display: flex;
}

.Button_Btn {
  @include fluid-type($min_width, $max_width, $min_font * 1, $max_font * 0.8);
  font-family: 'Gotham Bold';
  letter-spacing: 1.2px;
  margin: auto;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  color: white;
  text-align: center;
  text-decoration: none;
  &:active {
    opacity: 0.5;
  }
}

.inactive {
  opacity: 0.3;
}
</style>
