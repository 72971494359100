import axios from 'axios'
import api from '@/services/api'
import store from '@/store'

export async function fetchEvents () {
  const events = localStorage /* eslint-disable-line */
    .getItem('events')
    .split(',')
  await storeEvents(events[0])
  // for the moment, get default wording
  store.dispatch(
    'langs/setMessages',
    store.getters['events/getEvents'].default.interfaceContent
  )
}

export async function fetchCards () {
  const cardsData = await getJsonFromUrl(
    store.getters['langs/wording'].cards.data.media['en-US'][0].objects.original
  )
  store.dispatch('cards/SetCardsData', cardsData)
}

export async function setTheme () {
  const wording = store.getters['langs/wording']
  const theme = {}
  Object.keys(wording.theme).forEach(key => {
    theme[key] = wording.theme[key]['en-US']
  })
  store.dispatch('theme/SetTheme', theme)
}

export function getSettingsUrl (settings) {
  const promises = [] // return to promises all
  const settingsObj = Object.keys(settings)
  for (const setting of settingsObj) {
    const url = settings[setting][0].objects.original
    const p1 = new Promise((resolve, reject) =>
      getJsonFromUrl(url)
        .then(json => {
          if (json) resolve(json)
        })
        .catch(e => {
          console.error(e)
          reject(e)
        })
    )
    promises.push(p1)
  }
  return promises
}

export async function getJsonFromUrl (url) {
  try {
    const json = await axios.get(url)
    return json.data
  } catch (e) {
    console.error(e)
  }
}

export async function storeEvents (event) {
  const token = localStorage.getItem('token') /* eslint-disable-line */
  try {
    const res = await api.devices.getSpecifics(event, token)
    const settings = res.data.data.settings
    store.dispatch('langs/setLangs', res.data.data.langs)
    if (settings && Object.keys(settings).length) {
      const promises = getSettingsUrl(settings)
      const configJson = await Promise.all(promises)
      if (configJson.length) {
        Object.keys(res.data.data.settings).forEach((el, i) => {
          res.data.data.settings[el] = configJson[i]
        })
      }
    }
    store.dispatch('events/AddEvent', { name: event, data: res.data.data })
  } catch (e) {
    console.error(e)
  }
}
