<template>
  <div class="CloseButton__Container" @click="returnHome">
    <div class="CloseButton__Icon" />
  </div>
</template>

<script>
export default {
  name: 'close-button',
  data () {
    return {}
  },
  methods: {
    returnHome () {
      if (this.$route.name === 'visit') this.$router.push({ name: 'showroom' })
      else this.$router.push({ name: 'lobby' })
    }
  }
}
</script>

<style lang="scss" scoped>
.CloseButton__Container {
  position: fixed;
  top: 0.8em;
  right: 0.8em;
  z-index: 19;
  width: 40px;
  height: 40px;
  background: rgb(255, 255, 255);
  border-radius: 100%;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &:active {
    transform: scale(0.95);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: 425px) {
    z-index: 9;
  }
}
.CloseButton__Icon {
  height: 50%;
  width: 50%;
  background-color: black;
  mask-image: url('../../assets/icons/close.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}
</style>
