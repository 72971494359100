<template>
  <div
    :class="[
      'ModaleCgu_Container',
      { Cgu__shrinked: $route.name !== 'userPage' }
    ]"
  >
    <div class="ModaleCgu">
      <div class="ModaleCgu__Header">
        <p class="title">{{ title }}</p>
      </div>
      <div class="ModaleCgu__Content">
        <p class="ModaleCgu__Text" v-html="description" />
      </div>
      <div class="ModaleCgu__Footer">
        <CTA
          class="ModaleCgu__Btn"
          :label="$t('agree')"
          @clickButton="close(true)"
        />
        <CTA
          class="ModaleCgu__Btn"
          :label="$t('decline')"
          @clickButton="close(false)"
          outline
        />
      </div>
    </div>
  </div>
</template>

<script>
import { animOpacity } from '@/utils/animations.js'
import CTA from '@/components/button/CTA'

export default {
  name: 'modale-cgu',
  components: {
    CTA
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    }
  },
  mounted () {
    this.open()
  },
  beforeDestroy () {},
  methods: {
    open () {
      animOpacity('.ModaleCgu_Container', 0.7, 0, 1)
    },
    close (validate) {
      animOpacity('.ModaleCgu_Container', 0.7, 1, 0, () =>
        this.$emit('closeModaleCgu', validate)
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/main.scss';

.ModaleCgu_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  @media only screen and (max-height: 700px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.Cgu__shrinked {
  @media (orientation: portrait) {
    bottom: 60px;
  }
}

.ModaleCgu {
  position: relative;
  box-sizing: border-box;
  padding: 0 3rem;
  width: 500px;
  height: 730px;
  border-radius: 30px;
  background-color: white;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-height: 700px) {
    height: 100%;
    width: 400px;
    padding: 0 2rem;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    padding: 0 2rem;
  }
}

.ModaleCgu__Header {
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'Gotham Bold';
  @include fluid-type($min_width, $max_width, $min_font * 1.1, $max_font * 1.1);
}

.ModaleCgu__Content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.ModaleCgu__Text {
  font-family: 'Gotham Medium';
  @include fluid-type($min_width, $max_width, $min_font * 0.6, $max_font * 0.6);
  line-height: 0.9rem;
  overflow-y: scroll;
  touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
}

.ModaleCgu__Footer {
  height: 12%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModaleCgu__Btn {
  height: 70%;
  width: 48%;
}
</style>
