const state = {
  cardsData: '',
  cardID: ''
}

const getters = {
  cardsData: state => state.cardsData,
  cardID: state => state.cardID
}

const actions = {
  SetCardsData ({ commit }, Object) {
    commit('SET_CARDS_DATA', Object)
  },
  SetCardID ({ commit }, String) {
    commit('SET_CARD_ID', String)
  }
}

const mutations = {
  SET_CARDS_DATA (state, Object) {
    state.cardsData = Object
  },
  SET_CARD_ID (state, String) {
    state.cardID = String
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
