<template>
  <div class="Container" v-if="DisplaySelf()">
    <div class="LangContainer" v-if="!open" @click="open = true">
      <p class="LangContainer__Text">{{ GetCurrentLang() }}</p>
    </div>
    <div v-if="open" class="LangListing">
      <div
        class="LangContainer"
        v-for="lang in $store.getters['langs/langs']"
        :key="lang.iso"
        @click="SwitchLang(lang.iso)"
      >
        <p class="LangContainer__Text">{{ lang.code }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  },
  methods: {
    SwitchLang (iso) {
      this.open = false
      this.$store.dispatch('langs/setLang', iso)
    },
    GetCurrentLang () {
      for (let i = 0; i < this.$store.getters['langs/langs'].length; i++) {
        if (
          this.$store.getters['langs/langs'][i].iso ===
          this.$store.getters['langs/lang']
        ) {
          return this.$store.getters['langs/langs'][i].code
        }
      }
      return ''
    },
    DisplaySelf () {
      const whitelist = [
        'login',
        'twofa',
        'forgotPassword',
        'setPassword',
        'emailConfirmation',
        'handle2fa',
        'register',
        'userPage'
      ]
      return (
        whitelist.indexOf(this.$route.name) === -1 &&
        !this.$store.getters['session/loadingAppData']
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.Container {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 999999;
}

.LangContainer {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
  &__Text {
    margin: auto;
    color: black;
    font-size: 0.8em;
    font-family: 'Gotham Book';
  }
}

.LangListing {
  display: flex;
  flex-direction: column;
}
</style>
