<template>
  <div class="Container" v-if="DisplaySelf()">
    <div class="LangContainer" @click="toggleOpen">
      <p class="LangContainer__Text">{{ GetCurrentLang() }}</p>
      <img
        :src="
          open
            ? require('../../assets/icons/close.svg')
            : require('../../assets/icons/arrow-down.svg')
        "
      />
    </div>
    <div v-if="open" class="LangListing">
      <div class="LangListing__Content">
        <div
          class="LangEl"
          v-for="lang in langs.filter(
            el => el.iso !== $store.getters['langs/lang']
          )"
          :key="lang.iso"
          @click="SwitchLang(lang.iso)"
        >
          <p class="LangContainer__Text">{{ lang.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  },
  computed: {
    langs () {
      // return this.$store.getters['langs/langs']
      const langs = [
        {
          isLeftToRight: true,
          name: 'English',
          originalName: 'English',
          iso: 'en-US',
          code: 'EN'
        },
        {
          isLeftToRight: true,
          name: 'Français',
          originalName: 'Français',
          iso: 'fr-FR',
          code: 'FR'
        },
        {
          isLeftToRight: true,
          name: 'Italo',
          originalName: 'Italo',
          iso: 'it-IT',
          code: 'IT'
        }
      ]
      return langs
    }
  },
  methods: {
    validateSelection (value) {
      console.log(value)
    },
    toggleOpen () {
      this.open = !this.open
    },
    SwitchLang (iso) {
      this.toggleOpen()
      this.$store.dispatch('langs/setLang', iso)
    },
    GetCurrentLang () {
      for (let i = 0; i < this.langs.length; i++) {
        if (this.langs[i].iso === this.$store.getters['langs/lang']) {
          return this.langs[i].name
        }
      }
      return ''
    },
    DisplaySelf () {
      const whitelist = [
        'login',
        'twofa',
        'forgotPassword',
        'setPassword',
        'emailConfirmation',
        'handle2fa',
        'register',
        'userPage'
      ]
      return (
        whitelist.indexOf(this.$route.name) === -1 &&
        !this.$store.getters['session/loadingAppData']
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.Container {
  position: relative;
}

.LangContainer {
  position: relative;
  width: 100%;
  height: 40px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: black 1px solid;
  margin-bottom: 1em;
  box-sizing: border-box;
  padding-left: 3%;
  &__Text {
    color: black;
    font-size: 0.8em;
    font-family: 'Gotham Book';
  }
  img {
    height: 50%;
    object-fit: contain;
  }
}

.LangListing {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 40px;
  max-height: 124px;
}

.LangListing__Content {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
}

.LangEl {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background: white;
  display: flex;
  align-items: center;
  padding-left: 10%;
  border-bottom: rgba(128, 128, 128, 0.2) 1px solid;
}

div,
p {
  touch-action: auto !important;
  -webkit-overflow-scrolling: touch;
}
</style>
