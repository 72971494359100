// export const recurse = (obj, iso) => {
//    if (obj[iso]) {
//       return obj[iso]
//    } else {
//       return obj
//    }
//    // /* flatten obj */
//    // const flatten = Object.flatten(obj)
//    // /* get iso key */
//    // const keys = iso.map((el) => el.iso)
//    // /* reverse */
//    // const temp = reverse(flatten, keys)
//    // /* return reverse obj with iso key first */
//    // return Object.unflatten(temp)
// }

/* flatten */
Object.flatten = function (data) {
  const result = {}

  function recurse (cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur
    } else if (Array.isArray(cur)) {
      for (let i = 0, l = cur.length; i < l; i++) {
        recurse(cur[i], prop + '[' + i + ']')
        if (l === 0) {
          result[prop] = []
        }
      }
    } else {
      let isEmpty = true
      for (const p in cur) {
        isEmpty = false
        recurse(cur[p], prop ? prop + '.' + p : p)
      }
      if (isEmpty && prop) {
        result[prop] = {}
      }
    }
  }
  recurse(data, '')
  return result
}
/* unflatten */
Object.unflatten = (obj = {}) => {
  const result = {}
  let temp, substrings, property, i
  for (property in obj) {
    substrings = property.split('.')
    temp = result
    for (i = 0; i < substrings.length - 1; i++) {
      if (!(substrings[i] in temp)) {
        if (isFinite(substrings[i + 1])) {
          temp[substrings[i]] = []
        } else {
          temp[substrings[i]] = {}
        }
      }
      temp = temp[substrings[i]]
    }
    temp[substrings[substrings.length - 1]] = obj[property]
  }
  return result
}
/* reverse */
const reverse = (obj, iso, separator = '.') => {
  const temp = {}
  Object.keys(obj).forEach(el => {
    const split = el.split(separator)
    /* mnemonisation lastItem */
    const lastItem = split[split.length - 1]
    /* check if last key is a i18n key */
    if (iso.indexOf(lastItem) !== -1) {
      /* remove iso key */
      split.pop()
      /* reverse and put iso key at first place */
      split.unshift(lastItem)
      temp[split.join(separator)] = obj[el]
      return temp
    }
  })
  return temp
}

/*
 * function to use to use vue-i18n with b-feeds wording
 * @params obj : wording of b-feeds
 * return iso key first only on wording // can be upgrade if needed in reverse
 */

export const parserI18N = (obj, iso) => {
  /* flatten obj */
  const flatten = Object.flatten(obj)
  /* get iso key */
  const keys = iso.map(el => el.iso)
  /* reverse */
  const temp = reverse(flatten, keys)
  /* return reverse obj with iso key first */
  return Object.unflatten(temp)
}
