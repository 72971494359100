<template>
  <div class="LoginField_Container">
    <div class="LoginField_InputContainer">
      <div class="LoginField_Text">
        <input
          v-model="content"
          v-if="InputType === 'email'"
          class="LoginField_Input"
          type="email"
          required
          :placeholder="placeholder || 'Email'"
        />
        <input
          v-model="content"
          v-if="InputType === 'password'"
          class="LoginField_Input"
          :type="passwordVisible ? 'text' : 'password'"
          :placeholder="placeholder || 'Password'"
        />
        <input
          v-model="content"
          v-if="InputType === 'text'"
          class="LoginField_Input"
          type="text"
          :placeholder="placeholder"
        />
        <div v-if="InputType === 'password'" class="LoginField_ShowPassword">
          <button
            @click="passwordVisible = !passwordVisible"
            class="LoginField_Button"
          >
            <div
              :class="[
                !passwordVisible
                  ? 'LoginField_Icon LoginField_ViewIcon'
                  : 'LoginField_Icon LoginField_HideIcon'
              ]"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="LoginField_Warning">
      <p>{{ warning }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      passwordVisible: false,
      warning: '',
      content: '',
      valid: false
    }
  },
  watch: {
    content: function (value) {
      if (value || value === '' || value === ' ') {
        if (this.InputType === 'email') {
          this.VerifyEmail(value)
        } else if (this.InputType === 'password') {
          this.VerifyPassword(value)
        } else if (this.InputType === 'text') {
          this.$emit('textChanged', value)
        }
      }
    }
  },
  props: {
    InputType: {
      type: String,
      default: 'email'
    },
    placeholder: {
      type: String,
      default: null
    },
    autocompletion: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.content = this.autocompletion
  },
  methods: {
    VerifyEmail (value) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.valid = regex.test(value)
      this.$emit('emailChanged', value)
      if (this.valid || value.length === 0) {
        this.warning = ''
      } else {
        this.warning = 'Invalid email address'
      }
    },
    VerifyPassword (value) {
      this.valid = value.length >= 8
      this.$emit('pwdChanged', value)
      if (this.valid || value.length === 0) {
        this.warning = ''
      } else {
        this.warning = 'Password must contain at least 8 characters'
      }
    },
    SetEmptyEmailWarning () {
      this.warning = 'You must provide an email address'
    },
    SetEmptyPasswordWarning () {
      this.warning = 'You must provide a password'
    }
  }
}
</script>

<style scoped>
.LoginField_Container {
  width: 100%;
  height: 100%;
}

.LoginField_Container:nth-of-type(1) {
  margin-bottom: 1rem;
}

.LoginField_InputContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: #636363;
  justify-content: flex-start;
  position: relative;
}

.LoginField_IconContainer {
  width: 20%;
  height: 100%;
  display: flex;
}

.LoginField_Text {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
}

.LoginField_ShowPassword {
  position: absolute;
  right: 0;
  width: 20%;
  height: 100%;
  display: flex;
}

.LoginField_Button {
  width: 100%;
  height: 100%;
}

.LoginField_Input {
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
  height: 100%;
  background-color: #edecec !important;
  font-family: 'Gotham Medium' !important;
  color: #636363;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #edecec inset !important;
  -webkit-text-fill-color: #636363;
}

input:-webkit-autofill::first-line {
  font-family: 'Gotham Medium' !important;
}

.LoginField_Icon {
  margin: auto;
  height: 50%;
  width: 50%;
  background-color: #636363;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  overflow: hidden;
  box-sizing: border-box;
}

.LoginField_EmailIcon {
  margin-left: 0px;
  mask-image: url('../../../assets/icons/at.svg');
}

.LoginField_PasswordIcon {
  margin-left: 0px;
  mask-image: url('../../../assets/icons/lock-open.svg');
}

.LoginField_ViewIcon {
  width: 100%;
  height: 15px;
  margin-right: 0px;
  mask-image: url('../../../assets/icons/eye.svg');
}

.LoginField_HideIcon {
  width: 100%;
  height: 15px;
  margin-right: 0px;
  mask-image: url('../../../assets/icons/eye-off.svg');
}

.LoginField_Warning {
  color: darkred;
  font-size: 0.8em;
}
</style>
