export default [
  {
    path: '/visit-selection',
    name: 'showroom',
    meta: { module: 'showroom' },
    component: () => import('@/modules/visit/views/selection/Selection.vue')
  },
  {
    path: '/visit/:id',
    name: 'visit',
    meta: { module: 'showroom' },
    component: () => import('@/modules/visit/views/visitRoot/VisitRoot.vue')
  }
]
