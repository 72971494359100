const state = {
  loadingAppData: false,
  settingsOpen: false,
  hasUser: false,
  alreadySeenTutos: [],
  displaysTuto: false
}

const getters = {
  loadingAppData: state => state.loadingAppData,
  settingsOpen: state => state.settingsOpen,
  hasUser: state => state.hasUser,
  getAlreadySeenTutos: state => state.alreadySeenTutos,
  displaysTuto: state => state.displaysTuto
}

const actions = {
  SetLoadingAppData ({ commit }, Boolean) {
    commit('SET_LOADING_APP_DATA', Boolean)
  },
  SetSettingsOpen ({ commit }, Boolean) {
    commit('SET_SETTINGS_OPEN', Boolean)
  },
  SetSessionUser ({ commit }, Boolean) {
    commit('SET_SESSION_USER', Boolean)
  },
  addSeenTuto ({ commit }, pageName) {
    commit('ADD_SEEN_TUTO', pageName)
  },
  displaysTuto ({ commit }, Boolean) {
    commit('DISPLAYS_TUTO', Boolean)
  }
}

const mutations = {
  SET_LOADING_APP_DATA (state, Boolean) {
    state.loadingAppData = Boolean
  },
  SET_SETTINGS_OPEN (state, Boolean) {
    state.settingsOpen = Boolean
  },
  SET_SESSION_USER (state, Boolean) {
    state.hasUser = Boolean
  },
  ADD_SEEN_TUTO (state, pageName) {
    state.alreadySeenTutos.push(pageName)
  },
  DISPLAYS_TUTO (state, Boolean) {
    state.displaysTuto = Boolean
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
