const state = {}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
