<template>
  <div class="Notification__Container" :style="{ background: GetColor() }">
    <p class="Notification__Text">{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    message: { default: '' },
    type: { default: '' }
  },
  methods: {
    GetColor () {
      switch (this.type) {
        case 'error':
          return 'rgba(255,20,10,0.5)'
        case 'notification':
          return 'rgba(0,255,50,0.5)'
        case 'warning':
          return 'rgba(0,0,0,0.8)'
      }
    }
  }
}
</script>

<style scoped>
.Notification__Container {
  display: flex;
  align-content: center;
  background: rgb(255, 255, 255, 0.7);
  padding: 1em;
}

.Notification__Text {
  margin: auto;
  color: white;
}
</style>
