export const formatDate = date => {
  // format mm/dd/yyyy
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [month, day, year].join('/')
}

export const formatTime = date => {
  // format hh:mm pm/am
  const d = new Date(date)
  let hour = d.getHours()
  let min = d.getMinutes()
  if (min < 10) min = '0' + min
  let ampm = 'am'
  if (hour > 12) {
    hour -= 12
    ampm = 'pm'
  }
  return `${hour}:${min} ${ampm.toUpperCase()}`
}
