const state = {
  mapOpen: false,
  cardOpen: false,
  mapUrl: '',
  mapData: '',
  cardID: ''
}

const getters = {
  mapOpen: state => state.mapOpen,
  cardOpen: state => state.cardOpen,
  mapUrl: state => state.mapUrl,
  mapData: state => state.mapData,
  cardID: state => state.cardID
}

const actions = {
  SetMapOpen ({ commit }, Boolean) {
    commit('SET_MAP_OPEN', Boolean)
  },
  SetCardOpen ({ commit }, Boolean) {
    commit('SET_CARD_OPEN', Boolean)
  },
  SetMapURL ({ commit }, String) {
    commit('SET_MAP_URL', String)
  },
  SetMapData ({ commit }, Object) {
    commit('SET_MAP_DATA', Object)
  },
  SetCardID ({ commit }, String) {
    commit('SET_CARD_ID', String)
  }
}

const mutations = {
  SET_MAP_OPEN (state, Boolean) {
    state.mapOpen = Boolean
  },
  SET_CARD_OPEN (state, Boolean) {
    state.cardOpen = Boolean
  },
  SET_MAP_URL (state, String) {
    state.mapUrl = String
  },
  SET_MAP_DATA (state, Object) {
    state.mapData = Object
  },
  SET_CARD_ID (state, String) {
    state.cardID = String
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
