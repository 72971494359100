<template>
  <div
    class="Switch"
    :style="{
      'background-color': active
        ? $store.getters['theme/theme'].secondary
        : '#FFFFFF'
    }"
  >
    <div class="button" @click="toggle" :class="{ active: active }">
      <div class="button-inner" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'switch-button',
  components: {},
  props: {
    active: { type: Boolean }
  },
  methods: {
    toggle () {
      this.$emit('check')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/main.scss';

.Switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 49px;
  height: 29px;
  border-radius: 16px;
  border: 1px solid #e5e5e5;
  @media screen and (max-width: 425px) {
    width: 36px;
    height: 22px;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    width: 42px;
    height: 26px;
  }
}
.button {
  position: absolute;
  width: 26px;
  top: 0;
  bottom: 0;
  border-radius: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000026;
  border: 0.5px solid #0000000a;
  @media screen and (max-width: 425px) {
    width: 21px;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    width: 24px;
  }
}

.active {
  right: 0;
}
</style>
