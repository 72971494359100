const state = {
  token: ''
}

const getters = {
  getToken: state => state.token
}

const actions = {
  SetToken ({ commit }, token) {
    commit('SET_TOKEN', token)
  },
  StoreTokensLocally () {
    localStorage.setItem('token', state.token) /* eslint-disable-line */
  }
}

const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
