const defaultTheme = {
  primary: 'rgb(242, 233,225)',
  secondary: 'rgb(215, 50,30)',
  accent: '#ffffff',
  logoNavbar: '#ffffff',
  lightGrey: '#EDECEC',
  lightText: '#C8C8C8',
  greyText: '#636363'
}

const state = {
  theme: defaultTheme
}

const getters = {
  theme: state => state.theme
}

const actions = {
  SetTheme ({ commit }, Object) {
    commit('SET_THEME', Object)
  }
}

const mutations = {
  SET_THEME (state, Object) {
    state.theme = Object
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
