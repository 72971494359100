<template>
  <div
    class="Navbar"
    v-if="DisplaySelf()"
    :style="{ background: $store.getters['theme/theme'].primary }"
  >
    <div class="Navbar__Top">
      <div
        class="Element Element__Red"
        @click="ToLobby()"
        v-if="IsLandscape()"
        :style="{ background: $store.getters['theme/theme'].secondary }"
      >
        <div
          class="Icon Icon__Logo"
          :style="{ background: $store.getters['theme/theme'].logoNavbar }"
        />
      </div>
      <div class="Element" @click="ToLobby()">
        <div v-if="$route.name === 'lobby'" class="Element__Active" />
        <img
          class="Icon"
          :src="
            $route.name === 'lobby'
              ? require('../../assets/icons/lobby_in.svg')
              : require('../../assets/icons/lobby.svg')
          "
        />
      </div>
      <div class="Element" @click="ToShowroom()">
        <div v-if="IsIntoShowroom()" class="Element__Active" />
        <img
          class="Icon"
          :src="
            IsIntoShowroom()
              ? require('../../assets/icons/showroom_in.svg')
              : require('../../assets/icons/showroom.svg')
          "
        />
      </div>
      <div class="Element" @click="ToConferenceRoom()">
        <div v-if="$route.name === 'conference'" class="Element__Active" />
        <img
          class="Icon"
          :src="
            $route.name === 'conference'
              ? require('../../assets/icons/conference_in.svg')
              : require('../../assets/icons/conference.svg')
          "
        />
      </div>
      <div class="Element" @click="ToRelaxation()">
        <div v-if="$route.name === 'pantelleria'" class="Element__Active" />
        <img
          class="Icon"
          :src="
            $route.name === 'pantelleria'
              ? require('../../assets/icons/relax_in.svg')
              : require('../../assets/icons/relax.svg')
          "
        />
      </div>
      <div class="Element" @click="ToSustainability()">
        <div v-if="$route.name === 'sustainability'" class="Element__Active" />
        <img
          class="Icon"
          :src="
            $route.name === 'sustainability'
              ? require('../../assets/icons/sustain_in.svg')
              : require('../../assets/icons/sustain.svg')
          "
        />
      </div>
    </div>
    <div class="Navbar__Bottom">
      <div
        class="Element"
        @click="ToggleTuto()"
        :style="{ opacity: hasTuto ? 1 : 0.2 }"
      >
        <div v-if="showTuto" class="Element__Active" />
        <img
          class="Icon"
          :src="
            showTuto
              ? require('../../assets/icons/tuto_in.svg')
              : require('../../assets/icons/tuto.svg')
          "
        />
      </div>
      <div class="Element" @click="ToggleSettings()">
        <div
          v-if="$store.getters['session/settingsOpen']"
          class="Element__Active"
        />
        <img
          class="Icon"
          :src="
            $store.getters['session/settingsOpen']
              ? require('../../assets/icons/settings_in.svg')
              : require('../../assets/icons/settings.svg')
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { EventBus } from '@/event-bus.js'

export default {
  data () {
    return {}
  },
  computed: {
    showTuto () {
      return this.$store.getters['session/displaysTuto']
    },
    hasTuto () {
      return (
        this.$route.name === 'showroom' ||
        this.$route.name === 'pantelleria' ||
        this.$route.name === 'visit-selection' ||
        this.$route.name === 'sustainability'
      )
    }
  },
  methods: {
    ToLobby () {
      if (this.$route.name !== 'lobby') {
        this.$router.push({ name: 'lobby' })
      }
    },
    ToShowroom () {
      if (this.$route.name !== 'showroom') {
        this.$router.push({ name: 'showroom' })
      }
    },
    ToRelaxation () {
      if (this.$route.name !== 'pantelleria') {
        this.$router.push({ name: 'pantelleria' })
      }
    },
    ToSustainability () {
      if (this.$route.name !== 'sustainability') {
        this.$router.push({ name: 'sustainability' })
      }
    },
    ToConferenceRoom () {
      if (this.$route.name !== 'conference') {
        this.$router.push({ name: 'conference' })
      }
    },
    ToggleSettings () {
      this.$store.dispatch(
        'session/SetSettingsOpen',
        !this.$store.getters['session/settingsOpen']
      )
    },
    ToggleTuto () {
      if (this.hasTuto) {
        this.$store.dispatch(
          'session/displaysTuto',
          !this.$store.getters['session/displaysTuto']
        )
      }
    },
    IsLandscape () {
      return (
        window.innerWidth / window.innerHeight > 1 && window.innerWidth > 1000
      )
    },
    IsIntoShowroom () {
      return this.$route.name === 'showroom' || this.$route.name === 'visit'
    },
    DisplaySelf () {
      const whitelist = [
        'login',
        'twofa',
        'forgotPassword',
        'setPassword',
        'emailConfirmation',
        'handle2fa',
        'register',
        'userPage'
      ]
      return (
        whitelist.indexOf(this.$route.name) === -1 &&
        !this.$store.getters['theme/theme'].default
      )
    }
  }
}
</script>

<style scoped lang="scss">
@media (orientation: landscape) and (min-width: 1000px) {
  .Navbar {
    position: absolute;
    width: 60px;
    height: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 99999999;
    pointer-events: none;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    &__Top {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &__Bottom {
      width: 100%;
      height: 150px;
    }
  }
}

@media (orientation: landscape) and (max-width: 1000px) {
  .Navbar {
    position: absolute;
    width: 60px;
    height: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 99999999;
    pointer-events: none;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    &__Top {
      width: 100%;
      height: 70%;
      display: flex;
      flex-direction: column;
    }
    &__Bottom {
      width: 100%;
      height: 150px;
    }
  }
}

@media (orientation: portrait) {
  .Navbar {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    z-index: 99999999;
    pointer-events: none;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    &__Top {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }
    &__Bottom {
      display: flex;
      width: 20%;
      height: 100%;
    }
  }
}

.Element {
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  pointer-events: auto;
  &__Red {
    background: rgb(215, 50, 30);
  }
  @media (orientation: landscape) {
    margin-bottom: 30%;
    &__Active {
      position: absolute;
      left: 0;
      height: 100%;
      width: 3px;
      background: rgb(215, 50, 30);
    }
  }
  @media (orientation: landscape) and (max-height: 520px) {
    margin-bottom: 10%;
    height: 50px;
  }
  @media (orientation: portrait) {
    margin-right: 5%;
    &__Active {
      position: absolute;
      top: 0;
      width: 100%;
      height: 3px;
      background: rgb(215, 50, 30);
    }
  }
}

.Icon {
  margin: auto;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  width: 60%;
  height: 60%;
  &__Logo {
    background: white;
    mask-image: url('../../assets/icons/ArmaniGA.svg');
    width: 50%;
    height: 50%;
  }
}
</style>
