const state = {
  mapOpen: false,
  cardOpen: false,
  mapUrl: '',
  visitData: '',
  pov: 0,
  fullscreenData: '',
  videosActive: true,
  isDebug: false,
  scrollValue: 0
}

const getters = {
  mapOpen: state => state.mapOpen,
  cardOpen: state => state.cardOpen,
  mapUrl: state => state.mapUrl,
  visitData: state => state.visitData,
  pov: state => state.pov,
  fullscreenData: state => state.fullscreenData,
  videosActive: state => state.videosActive,
  isDebug: state => state.isDebug,
  scrollValue: state => state.scrollValue
}

const actions = {
  SetMapOpen ({ commit }, Boolean) {
    commit('SET_MAP_OPEN', Boolean)
  },
  SetCardOpen ({ commit }, Boolean) {
    commit('SET_CARD_OPEN', Boolean)
  },
  SetMapURL ({ commit }, String) {
    commit('SET_MAP_URL', String)
  },
  SetVisitData ({ commit }, Object) {
    commit('SET_VISIT_DATA', Object)
  },
  SetPOV ({ commit }, Int) {
    commit('SET_POV', Int)
  },
  SetFullscreenData ({ commit }, Object) {
    commit('SET_FULLSCREEN_DATA', Object)
  },
  SetVideosActive ({ commit }, Boolean) {
    commit('SET_VIDEOS_ACTIVE', Boolean)
  },
  SetDebug ({ commit }, Boolean) {
    commit('SET_DEBUG', Boolean)
  },
  SetScrollValue ({ commit }, Float) {
    commit('SET_SCROLL_VALUE', Float)
  }
}

const mutations = {
  SET_MAP_OPEN (state, Boolean) {
    state.mapOpen = Boolean
  },
  SET_CARD_OPEN (state, Boolean) {
    state.cardOpen = Boolean
  },
  SET_MAP_URL (state, String) {
    state.mapUrl = String
  },
  SET_VISIT_DATA (state, Object) {
    state.visitData = Object
  },
  SET_POV (state, Int) {
    state.pov = Int
  },
  SET_FULLSCREEN_DATA (state, Object) {
    state.fullscreenData = Object
  },
  SET_VIDEOS_ACTIVE (state, Boolean) {
    state.videosActive = Boolean
  },
  SET_DEBUG (state, Boolean) {
    state.isDebug = Boolean
  },
  SET_SCROLL_VALUE (state, Float) {
    state.scrollValue = Float
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
