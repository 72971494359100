<template>
  <div id="app" :key="componentKey">
    <router-view />
    <Notificator />
    <CloseButton v-if="canClose" />
    <LanguageSelection
      v-if="$store.getters['langs/langs'].length > 1"
      class="LangSelector"
    />
    <Panel v-if="$store.getters['session/settingsOpen']" />
    <Navbar />
    <InfiniteLoader
      class="Data__Loader"
      v-if="
        $store.getters['session/loadingAppData'] && this.$route.name !== 'login'
      "
      :color="'0, 0, 0'"
    />
  </div>
</template>

<script>
import Notificator from '@/components/notificator/Notificator'
import LanguageSelection from '@/components/language/LanguageSelection'
import CloseButton from '@/components/closebutton/CloseButton'
import InfiniteLoader from '@/components/loader/InfiniteLoader.vue'
import Navbar from '@/components/navbar/Navbar.vue'
import Panel from '@/components/navbar/Panel.vue'

export default {
  data () {
    return {
      componentKey: 0
    }
  },
  mounted () {
    this.$store.dispatch('langs/setLang', 'en-US')
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  components: {
    Notificator,
    LanguageSelection,
    InfiniteLoader,
    Navbar,
    Panel,
    CloseButton
  },
  computed: {
    canClose () {
      return (
        this.$route.name === 'showroom' ||
        this.$route.name === 'pantelleria' ||
        this.$route.name === 'visit-selection' ||
        this.$route.name === 'sustainability' ||
        this.$route.name === 'visit' ||
        this.$route.name === 'conference'
      )
    }
  },
  methods: {
    onResize () {
      this.componentKey += 1
    }
  }
}
</script>

<style lang="scss">
@import './styles/main.scss';

html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior-y: contain;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  touch-action: none;
  overflow: hidden;
  display: flex;
}

/* http://meyerweb.com/eric/tools/css/reset/
 v2.0 | 20110126
 License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: 'Open Sans', sans-serif;
  touch-action: none;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input {
  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;
}

.LangSelector {
  position: absolute;
  right: 1em;
  top: 1em;
}

.TextAbsolute {
  position: absolute;
  right: 1em;
  top: 1em;
}

.Data__Loader {
  width: 50px;
  height: 50px;
  margin: auto;
}
</style>
