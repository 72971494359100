import axios from 'axios'
import store from '@/store'

const getHeaders = function () {
  return {
    Authorization: `Bearer ${store.getters.getToken}`
  }
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000
})

const instanceConsultationRooms = axios.create({
  baseURL: process.env.VUE_APP_CONSULTATION_ROOMS_URL,
  timeout: 10000
})

export default {
  auth: {
    forgotPassword (email, origin = undefined) {
      const payload =
        origin !== undefined
          ? { emailAddress: email, origin: origin }
          : { emailAddress: email }
      return instance.put(
        '/api/v1/entrance/send-password-recovery-email',
        payload
      )
    },

    login (email, password) {
      return instance.put('/api/v1/entrance/authentication', {
        emailAddress: email,
        password: password
      })
    },

    verify2Fa (token) {
      return instance.put(
        '/api/v1/account/verify-2fa',
        { token },
        { headers: getHeaders() }
      )
    },

    logout () {
      return instance.get('/auth/logout', { headers: getHeaders() })
    },

    setPassword (token, password, firstTime = false) {
      const body = { password: password }
      if (firstTime) body.emailProofToken = token
      else body.passwordResetToken = token
      return instance.put('/api/v1/entrance/update-password', body)
    },

    confirmEmail (token) {
      return instance.get(`/api/v1/entrance/confirm-email/${token}`)
    },

    handle2fa () {
      return instance.get('/api/v1/account/handle-2fa', {
        headers: getHeaders()
      })
    }
  },

  wordings: {
    get () {
      return instance.get(
        `/api/v1/wordings/${process.env.VUE_APP_WORDING_ID}`,
        { headers: getHeaders() }
      )
    }
  },

  devices: {
    get (email, password) {
      return instance.put('/api/v1/devices/get-devices', {
        emailAddress: email,
        password: password
      })
    },

    getSpecifics (specific, token) {
      return instance.get(`/api/v2/devices/get-catalog?specific=${specific}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
    }
  },

  catalog: {
    get () {
      return instance.get('/api/v3/devices/get-catalog', {
        headers: {
          Authorization: `Bearer ${'wcx2tbJbZjvbqcD4gdYNggfTJFpw3KNBl5sW.jMQzL1AW5Fpf8AXm6cPvwLPeSUvXaRyQl8zF'}`
        }
      })
    }
  },

  consultation: {
    getRooms () {
      return instanceConsultationRooms.get('/available-rooms')
    }
  },

  products: {
    get (id) {
      return instance.get(`/api/v1/products/${id}`, { headers: getHeaders() })
    }
  },

  shops: {
    getAll () {
      return instance.get('/api/v1/shops', { headers: getHeaders() })
    },

    get (id) {
      return instance.get(`/api/v1/shops/${id}`, { headers: getHeaders() })
    }
  },

  users: {
    create (body) {
      return instance.post('/api/v1/users', body, { headers: getHeaders() })
    },

    getAll () {
      return instance.get('/api/v1/users', { headers: getHeaders() })
    }
  },

  zoning: {
    get (id) {
      return instance.get(`/api/v1/zoning/${id}`, { headers: getHeaders() })
    }
  },

  settings: {
    get (url) {
      // get JSON
      return axios.get(url)
    }
  }
}
