import gsap from 'gsap'

export function translateX (target, seconds, fromX, cb) {
  gsap.from(target, {
    duration: seconds,
    x: fromX,
    ease: 'power3.out',
    onComplete: cb
  })
}

export function animOpacity (target, seconds, from, to, cb) {
  gsap.fromTo(
    target,
    {
      opacity: from
    },
    {
      duration: seconds,
      opacity: to,
      ease: 'power3.inOut',
      onComplete: cb
    }
  )
}

export function slowPulse (target, seconds) {
  const tl = gsap.timeline({ repeat: -1 })
  tl.fromTo(
    target,
    {
      opacity: 0,
      scaleX: 0.7,
      scaleY: 0.7
    },
    {
      duration: seconds,
      opacity: 1,
      scaleX: 1,
      scaleY: 1
    }
  )
  tl.to(target, {
    duration: seconds,
    opacity: 0,
    scaleX: 0.7,
    scaleY: 0.7
  })
}
