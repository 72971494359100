const state = {
  events: {}
}

const getters = {
  getEvents: state => state.events
}

const actions = {
  AddEvent ({ commit }, event) {
    commit('ADD_EVENT', event)
  }
}

const mutations = {
  ADD_EVENT (state, event) {
    state.events[event.name] = event.data
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
