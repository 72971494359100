import Vue from 'vue'
import VueRouter from 'vue-router'

import routerVisits from '@/modules/visit/router'
import routerRelax from '@/modules/relax-room/router'
import routerLobby from '@/modules/lobby/router'
import routerSustainability from '@/modules/sustainability/router'
import routerConference from '@/modules/conference/router'

import store from '@/store'
import { fetchEvents, fetchCards, setTheme } from '@/services/dataHandler'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/twofa',
    name: 'twofa',
    component: () => import('@/views/twofa/twofa.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('@/views/forgotPassword')
  },
  {
    path: '/user-page',
    name: 'userPage',
    component: () => import('@/views/userPage/userPage')
  },
  {
    path: '/entrance/reset-password/:token',
    name: 'setPassword',
    component: () => import('@/views/setPassword/setPassword')
  },
  {
    path: '/entrance/register',
    name: 'register',
    props: true,
    component: () => import('@/views/register/Register.vue')
  },
  {
    path: '/entrance/email-confirmation/:token',
    name: 'emailConfirmation',
    component: () => import('@/views/emailConfirmation/emailConfirmation.vue')
  },
  {
    path: '/entrance/handle-2fa',
    name: 'handle2fa',
    component: () => import('@/views/twofa/handle2fa.vue')
  },
  {
    path: '*',
    name: '404',
    redirect: { name: 'login' }
  }
]

const routes = baseRoutes.concat(
  routerVisits,
  routerRelax,
  routerLobby,
  routerSustainability,
  routerConference
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('session/SetSettingsOpen', false)
  store.dispatch('session/displaysTuto', false)
  if (to.name !== 'login' && to.name !== 'forgotPassword') {
    try {
      store.dispatch('session/SetLoadingAppData', true)
      if (!store.getters['events/getEvents'].default) {
        await fetchEvents()
        await setTheme()
      }
      if (!store.getters['cards/cardsData'].default) {
        await fetchCards()
      }
    } catch (e) {
      console.error(e)
      next('login')
    } finally {
      store.dispatch('session/SetLoadingAppData', false)
    }
  }
  next()
})

export default router
