<template>
  <div
    class="loader"
    :style="{
      'border-right': width + ' solid rgba(' + color + ', 0.2)',
      'border-top': width + ' solid rgba(' + color + ', 0.2)',
      'border-bottom': width + ' solid rgba(' + color + ', 0.2)',
      'border-left': width + ' solid rgba(' + color + ', 1)'
    }"
  />
</template>

<script>
export default {
  props: {
    width: { default: '0.5em' },
    color: { default: '255, 255, 255' }
  }
}
</script>

<style scoped>
.loader,
.loader:after {
  border-radius: 50%;
}
.loader {
  font-size: 10px;
  border-left: 1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
