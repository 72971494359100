<template>
  <div class="Notificator__Container">
    <div class="Notificator__List">
      <Notification
        class="Notificator__Notification"
        v-for="(notif, index) in notificationsQueue"
        :key="index"
        :message="notif.data.message"
        :type="notif.data.type"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import Notification from './Notification.vue'

export default {
  data () {
    return {
      notificationsQueue: [],
      timeMarker: ''
    }
  },
  components: {
    Notification
  },
  mounted () {
    EventBus.$on('notify', data => {
      this.AddNotification(data)
    })
    this.timeMarker = Date.now()
    setInterval(() => this.CheckTime(), 1000)
  },
  methods: {
    AddNotification (data) {
      if (this.notificationsQueue.length > 4) {
        this.DeleteNotif()
      }
      this.notificationsQueue.push({ data })
      this.timeMarker = Date.now()
    },
    CheckTime () {
      const timeLeft = Date.now() - this.timeMarker
      if (timeLeft > 2000) {
        this.DeleteNotif()
      }
    },
    DeleteNotif () {
      this.notificationsQueue.splice(0, this.notificationsQueue.length)
    }
  }
}
</script>

<style scoped lang="scss">
.Notificator__Container {
  position: absolute;
  z-index: 500;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Notificator__List {
  width: 10em;
  height: 50%;
  align-self: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;
  @media (orientation: portrait) {
    margin-bottom: 60px;
  }
}

.Notificator__Notification {
  min-width: 100%;
  min-height: 4em;
  margin: 0.2em;
  box-sizing: border-box;
  filter: drop-shadow(0 0.5mm 0.5mm rgba(0, 0, 0, 0.9));
  pointer-events: auto;
}
</style>
